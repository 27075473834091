import React from "react"
import styled from "styled-components"

const StyledIFrame = styled.iframe`
  width: 100%;
  border: none;
`
const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className="video">
    <StyledIFrame
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </div>
)
export default Video
