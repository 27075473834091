import React from "react"
import Video from "../../../components/Video"
import Layout from "../../../containers/layout"

const list = () => {
  return (
    <Layout>
      <Video
        videoSrcURL="https://www.youtube.com/embed/1RcTDdsnh_k"
        videoTitle="Official Music Video on YouTube"
      />
    </Layout>
  )
}

export default list
